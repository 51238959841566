import React, { useState } from "react"
import styled from "styled-components"
import TextHighlight from "./TextHighlight"
import { FilterItems } from "./FilterItems"
import { Arrow } from "../pages/best-practices"
import NewBadge, { NewBadgeText } from "./NewBadge"
import { css } from "../../.cache/css-to-object"

const DDSection = styled.div`
  margin-top: 0;
  cursor: pointer;
  display: grid;
  grid-column: 9 / span 5;
  grid-row: 12;
  z-index: 10;
  height: 65px;
  @media screen and (max-width: 90rem) {
    grid-column: 6 / span 2;
    grid-row: 13;
    height: 45px;
  }
  @media screen and (max-width: 50rem) {
    grid-column: 2 / span 6;
    grid-row: 18;
    margin-top: 0;
  }
  @media screen and (max-width: 37rem) {
    grid-column: 2 / span 6;
  }
`
const DDWrapper = styled.div`
  position: relative;
  height: 65.266px;
  background-color: ${props => props.theme.colors.darkHighlight};
  width: 23vw;
  justify-self: end;
  @media screen and (max-width: 90rem) {
    height: 45px;
  }
  @media screen and (max-width: 50rem) {
    width: 100%;
    justify-self: start;
  }
`
const DDHeader = styled.div`
  padding: 14px 1vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 90rem) {
    height: 45px;
    padding: 0 1vw;
  } 
  @media screen and (max-width: 37rem) {
    height: 45px;
    padding: 0 1.9vw;
  } 
`
const DDHeaderTitle = styled.p`
  padding: 0;
  font-size: 1.5em;
  text-transform: uppercase;
  color: ${({ filterActive }) => (filterActive ? "#9b99ff" : "#e4e6eb")};
  @media screen and (max-width: 90rem) {
    font-size: 1em;
  }
  @media screen and (max-width: 46rem) {
    font-size: 0.85em;
  }
  ${props =>
          !props.filterActive &&
          css`
            &:hover {
              color: #cdccff;
            }
    `}

`
const DDList = styled.ul`
  cursor: pointer;
  background-color: ${props => props.theme.colors.darkHighlight};
  list-style-type: none;
  padding: 0.5vw 0;
  margin-bottom: 1vw;
`
const DDListItem = styled.li`
  padding: 0.5vw 0 0.5vw 1vw;
`
const DDListButton = styled.button`
  cursor: pointer;
  margin-bottom: 0;
  font-family: "Archivo Regular", sans-serif;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 1.3em;
  text-transform: uppercase;
  letter-spacing: 0.09em;

  @media screen and (max-width: 90rem) {
    font-size: 0.85em;
  }
`

function Dropdown({ title, items, setStatesParent, isActiveValues, colors }) {
  const [open, setOpen] = useState(false)
  let filterActive = false

  function handleOnClick(item) {
    setStatesParent[item.id - 1](true);
    setOpen(false);
  }

  for (let i = 0; i < isActiveValues.length; i++) {
    if (isActiveValues[i]) {
      filterActive = true;
    }
  }

  return (
    <DDSection>
    <DDWrapper>
      <DDHeader
        tabIndex={0}
        role="button"
        onKeyPress={() => setOpen(!open)}
        onClick={() => setOpen(!open)}
      >
        <DDHeaderTitle filterActive={filterActive}>{title}</DDHeaderTitle>
        {open ? (
          <svg width={"15px"} height={"15px"} transform="rotate(0)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.5 11.9">
            <defs />
            <g data-name="Ebene 2">
              {filterActive ? <path
                fill="#9b99ff"
                d="M19.2 10.4l-1.3 1.3a.9.9 0 01-1.2 0l-7-6.9-6.9 6.9a.9.9 0 01-1.2 0L.3 10.4a.9.9 0 010-1.3L9.1.3a.9.9 0 011.3 0l8.8 8.8a.9.9 0 010 1.3z"
                data-name="Ebene 1"
              />:<path
                fill="#e4e6eb"
                d="M19.2 10.4l-1.3 1.3a.9.9 0 01-1.2 0l-7-6.9-6.9 6.9a.9.9 0 01-1.2 0L.3 10.4a.9.9 0 010-1.3L9.1.3a.9.9 0 011.3 0l8.8 8.8a.9.9 0 010 1.3z"
                data-name="Ebene 1"
              />
              }
            </g>
          </svg>) : (
          <svg width={"15px"} height={"15px"} transform="rotate(180)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.5 11.9">
            <defs />
            <g data-name="Ebene 2">
              {filterActive ? <path
                fill="#9b99ff"
                d="M19.2 10.4l-1.3 1.3a.9.9 0 01-1.2 0l-7-6.9-6.9 6.9a.9.9 0 01-1.2 0L.3 10.4a.9.9 0 010-1.3L9.1.3a.9.9 0 011.3 0l8.8 8.8a.9.9 0 010 1.3z"
                data-name="Ebene 1"
              />:<path
                fill="#e4e6eb"
                d="M19.2 10.4l-1.3 1.3a.9.9 0 01-1.2 0l-7-6.9-6.9 6.9a.9.9 0 01-1.2 0L.3 10.4a.9.9 0 010-1.3L9.1.3a.9.9 0 011.3 0l8.8 8.8a.9.9 0 010 1.3z"
                data-name="Ebene 1"
              />
              }
            </g>
          </svg>
          )}
      </DDHeader>
      {open && (
        <DDList>
          {items.map(item => (
            <DDListItem key={item.id}>
              <DDListButton colors={colors} type="button" onClick={() => handleOnClick(item)}>
                <TextHighlight colorValue={colors[item.id - 1]}>
                  {isActiveValues[item.id - 1] && item.value}
                </TextHighlight>
                <TextHighlight useHover={true} colorHoverValue={colors[item.id - 1]}>
                  {!isActiveValues[item.id - 1] && item.value}
                </TextHighlight>
              </DDListButton>
            </DDListItem>
          ))}
        </DDList>
      )}
    </DDWrapper>
    </DDSection>
  )
}

export default Dropdown
