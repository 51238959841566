import React, { useState } from "react"
import Layout from "../../components/Layout"
import data from "../../hints/data.json"
import styled, {css} from "styled-components"
import { Link } from "gatsby"
import Seo from "../../components/Seo"
import TextHighlight from "../../components/TextHighlight"
import Button from "../../components/Button"
import Space from "../../components/Space"
import Dropdown from "../../components/Dropdown"
import {
  Anchor,
  AnchorLink,
  Header,
  HeaderPicture,
  SiteGoal,
} from "../../components/HeaderSection"
import {
  Card,
  CardContent,
  CardDescription,
  CardHeadline,
  CardRole,
  CardSection,
  CardTitle,
  Developer,
  FifthRole,
  FirstRole,
  FourthRole,
  InformationArchitect,
  InteractionDesigner,
  ResourcesPT,
  ResourcesPTBackground,
  RoleDescription,
  RoleHeadline,
  SecondRole,
  SustainabilityDirector,
  ThirdRole,
  VisualDesigner,
} from "../../components/BestPracticeStyled"
import CardBackground from "../../components/CardBackground"
import NewBadge, { BadgeSection, NewBadgeText } from "../../components/NewBadge"
import { FilterItems } from "../../components/FilterItems"
import {
  CATSubheadline,
  CATUppercase,
  PTButton,
  PTHeadline,
} from "../../components/CTASection"
import scrollTo from "gatsby-plugin-smoothscroll"
import ActiveFilter from "../../components/ActiveFilter"
import { FilterText } from "../resources"
import SDIcon from "../../icons/sd-icon.svg"
import DEVIcon from "../../icons/dev-icon.svg"
import IAIcon from "../../icons/ia-icon.svg"
import VDIcon from "../../icons/vd-icon.svg"
import IDIcon from "../../icons/id-icon.svg"
import { SmallIcon } from "../index"
import BestPracticeHeaderImg from "../../img/best-practices/best-practice header-picture.svg"

const FilterWrapper = styled.div`
  z-index: 1;
  margin-top: 0;
  grid-column: 2 / span 12;
  grid-row: 12;
  display: flex;
  align-items: center;
  background-color: ${props => props.theme.colors.cards};
  padding: 0 1vw;
  height: 65.266px;

  @media screen and (max-width: 90rem) {
    grid-column: 2 / span 6;
    grid-row: 13;
    margin-bottom: 0;
    height: 45px;
  }

  @media screen and (max-width: 50rem) {
    grid-row: 16;
  }
  @media screen and (max-width: 37rem) {
    padding: 0 1.9vw;
    grid-row: 16;
  }
`
export const ActiveFilterWrapper = styled.div`
  z-index: 1;
  margin-top: 0;
  grid-column: 2 / span 12;
  grid-row: 13;
  align-items: center;
  margin-bottom: 0;
  padding: 0;

  @media screen and (max-width: 90rem) {
    padding: 0;
    grid-column: 2 / span 6;
    grid-row: 14;
    ${props =>
            props.useMargin &&
            css`
            margin-top: 0;
    `};
  @media screen and (max-width: 78rem) {
    ${props =>
            props.useMargin &&
            css`
            margin-top: 0.5vw;
    `};
  }

  @media screen and (max-width: 50rem) {
    grid-row: 19;
    ${props =>
            props.useMargin &&
            css`
            margin-top: 0;
    `};
  }
  @media screen and (max-width: 37rem) {
    padding: 0.25vw 0;
    ${props =>
            props.useMargin &&
            css`
            margin-top: 0;
    `};
  }
`
const KeepDiscoveringSection = styled.div`
  justify-self: end;
  grid-column: 5 / span 9;
  text-align: right;
  grid-row: 15;
  margin-top: 10rem;
  @media screen and (max-width: 90rem) {
    grid-column: 2 / span 6;
    grid-row: 16;
    margin-top: 5rem;
  }
  @media screen and (max-width: 50rem) {
    grid-row: 21;
  }
  @media screen and (max-width: 37rem) {
    margin-top: 2.5rem;
  }
`

export default function BestPractices() {
  const [newActive, setNew] = useState(false)
  const [sustainableActive, setSustainable] = useState(false)
  const [developerActive, setDeveloper] = useState(false)
  const [infoArchActive, setInfoArch] = useState(false)
  const [visualDesignerActive, setVisualDesigner] = useState(false)
  const [interactionDesignerActive, setInteractionDesigner] = useState(false)
  const isBrowser = () => typeof window !== "undefined"
  const items = [
    {
      id: 1,
      value: "Information Architect",
    },
    {
      id: 2,
      value: "Visual Designer",
    },
    {
      id: 3,
      value: "Interaction Designer",
    },
  ]
  const shortFormWidth = 500;

  data.sort((a, b) => (a.timeStamp < b.timeStamp ? 1 : -1))
  let cards = []
  data.forEach(element => {
    if (
      (newActive &&
        Date.now() - new Date(element.timeStamp).getTime() < 2628002880) ||
      (element.role.includes("sustainability director") === true &&
        sustainableActive) ||
      (element.role.includes("developer") === true && developerActive) ||
      (element.role.includes("information architect") === true &&
        infoArchActive) ||
      (element.role.includes("visual designer") === true &&
        visualDesignerActive) ||
      (element.role.includes("interaction designer") === true &&
        interactionDesignerActive) ||
      (!newActive &&
        !sustainableActive &&
        !developerActive &&
        !infoArchActive &&
        !visualDesignerActive &&
        !interactionDesignerActive &&
        !element.role.includes("you"))
    ) {
      cards.push(
        <Link to={"/best-practices/" + element.slug}>
          <CardBackground colorValue={element.color}>
            <Card>
              <BadgeSection>
                {Date.now() - new Date(element.timeStamp).getTime() <
                2628002880 ? (
                  <NewBadge colorValue={element.color}>
                    <NewBadgeText>New</NewBadgeText>
                  </NewBadge>
                ) : (
                  <NewBadge colorValue={"hidden"}>
                    <NewBadgeText>New</NewBadgeText>
                  </NewBadge>
                )}
              </BadgeSection>
              <CardContent>
                <CardTitle>{element.title}</CardTitle>
                <CardDescription>{element.description}</CardDescription>
                <CardRole>
                  <TextHighlight colorValue={element.color}>
                    {element.role}
                  </TextHighlight>
                </CardRole>
              </CardContent>
            </Card>
          </CardBackground>
        </Link>
      )
    } else if (element.role.includes("you")) {
      cards.push(
        <a href="mailto:">
          <CardBackground colorValue={element.color}>
            <Card>
              <NewBadge colorValue={"hidden"}>
                <NewBadgeText>New</NewBadgeText>
              </NewBadge>
              <CardTitle>{element.title}</CardTitle>
              <CardDescription>
                {element.description}{" "}
                <TextHighlight>{element.email}</TextHighlight>
              </CardDescription>
              <CardRole>{element.role}</CardRole>
            </Card>
          </CardBackground>
        </a>
      )
    }
  })
  return (
    <Layout>
      <Seo />
      <Header>
        <div>
          <h1>
            <TextHighlight colorValue={"violet"}>Best Practices</TextHighlight>
            <br />
            Filterable
            <br />
            by Roles
          </h1>
          <h2>
            Start{" "}
            <TextHighlight colorValue={"violet"}>
              within your means
            </TextHighlight>
            .
          </h2>
          <AnchorLink onClick={() => scrollTo("#best-practice-goal")}>
            <Button primaryViolet alignLeft>
              i am Ready let's go!
            </Button>
          </AnchorLink>
          <Link to="/">
            <Button secundaryViolet alignRight>
              make web sustainable?
            </Button>
          </Link>
        </div>
      </Header>
      <HeaderPicture><img src={BestPracticeHeaderImg} alt={"Header picture of a desktop with green screen"}/></HeaderPicture>
      <Anchor id={"best-practice-goal"} />
      <SiteGoal>
        <h2>
          Presenting you with best practices to reduce the carbon emissions of
          a website, which you can filter according to your roles.
        </h2>
      </SiteGoal>
      <RoleHeadline>Role descriptions</RoleHeadline>
      <RoleDescription>
        These roles are ideal types. Depending on the project, the roles can be
        split or combined.
      </RoleDescription>
      <FirstRole>
        <SustainabilityDirector>
          Sustainablility Director
          <SmallIcon src={SDIcon} width={"60px"} alt={"Icon with the letters SD"}/>
        </SustainabilityDirector>
        <p>
          Knowledgeable in Sustainable Web Design and guides the team to create
          sustainable websites.
        </p>
      </FirstRole>
      <SecondRole>
        <Developer>Developer<SmallIcon src={DEVIcon} width={"60px"} alt={"Icon with the letters DEV"}/></Developer>
        <p>
          Someone who is responsible for the technical realization and an
          advisor during the design stage.
        </p>
      </SecondRole>
      <ThirdRole>
        <InformationArchitect>Information Architect<SmallIcon src={IAIcon} width={"60px"} alt={"Icon with the letters IA"}/></InformationArchitect>
        <p>Defines the Content and the page structure.</p>
      </ThirdRole>
      <FourthRole>
        <VisualDesigner>Visual Designer<SmallIcon src={VDIcon} width={"60px"} alt={"Icon with the letters VD"}/></VisualDesigner>
        <p>Develops the Look & Feel.</p>
      </FourthRole>
      <FifthRole>
        <InteractionDesigner>Interaction Designer<SmallIcon src={IDIcon} width={"60px"} alt={"Icon with the letters IA"}/></InteractionDesigner>
        <p>Optimizes user interactions.</p>
      </FifthRole>
      <Space big bpTen />
      <CardHeadline>
        {newActive ||
        sustainableActive ||
        developerActive ||
        infoArchActive ||
        visualDesignerActive ||
        interactionDesignerActive
          ? "Selected Practices"
          : "All Practices"}
        <TextHighlight colorValue={"secundaryDark"}>
          &nbsp;&nbsp;&nbsp;[{cards.length}]
        </TextHighlight>
      </CardHeadline>
      <Dropdown
        title="Designer"
        items={items}
        setStatesParent={[
          setInfoArch,
          setVisualDesigner,
          setInteractionDesigner,
        ]}
        isActiveValues={[
          infoArchActive,
          visualDesignerActive,
          interactionDesignerActive,
        ]}
        colors={["violet", "purple", "pink"]}
      />
      <FilterWrapper>
        <FilterItems
          colorValue={"green"}
          setStateParent={setNew}
          isActiveValue={newActive}
        >
          New
        </FilterItems>
        {isBrowser() && window.innerWidth > shortFormWidth ? <FilterItems
          colorValue={"turquoise"}
          setStateParent={setSustainable}
          isActiveValue={sustainableActive}
        >
          Sustainabili&shy;ty Director
        </FilterItems> : <FilterItems
          colorValue={"turquoise"}
          setStateParent={setSustainable}
          isActiveValue={sustainableActive}
        >
          S. Director
        </FilterItems>}
        <FilterItems
          colorValue={"blue"}
          setStateParent={setDeveloper}
          isActiveValue={developerActive}
        >
          Developer
        </FilterItems>
      </FilterWrapper>
      <ActiveFilterWrapper useMargin={(newActive ||
        sustainableActive ||
        developerActive ||
        infoArchActive ||
        visualDesignerActive ||
        interactionDesignerActive)}>
        <ActiveFilter isActiveValue={newActive} setStateParent={setNew} colorValue={"new"}>
          <FilterText>
            <TextHighlight colorValue={"green"}>new</TextHighlight>
            {isBrowser() && window.innerWidth > shortFormWidth ? <svg
              height={"15px"}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 40 20"
            >
              <defs />
              <g data-name="Ebene 2">
                <g data-name="Ebene 1">
                  <path fill="none" d="M0 0h40v20H0z" />
                  <path
                    fill="#72dbaa"
                    d="M32.8 10l6.9-6.9a1 1 0 000-1.4L38.3.3a1 1 0 00-1.4 0L30 7.2 23.1.3a1 1 0 00-1.4 0l-1.4 1.4a1 1 0 000 1.4l6.9 6.9-6.9 6.9a1 1 0 000 1.4l1.4 1.4a1 1 0 001.4 0l6.9-6.9 6.9 6.9a1 1 0 001.4 0l1.4-1.4a1 1 0 000-1.4z"
                  />
                </g>
              </g>
            </svg> : <TextHighlight colorValue={"boldGreen"}>&nbsp;&nbsp;&nbsp;&nbsp;x</TextHighlight>}
          </FilterText>
        </ActiveFilter>
        <ActiveFilter isActiveValue={sustainableActive} setStateParent={setSustainable} colorValue={"sd"}>
          <FilterText>
            <TextHighlight show={isBrowser() && window.innerWidth > shortFormWidth} colorValue={"turquoise"}>sustainability director</TextHighlight>
            <TextHighlight show={isBrowser() && window.innerWidth <= shortFormWidth} colorValue={"turquoise"}>sd</TextHighlight>
            {isBrowser() && window.innerWidth > shortFormWidth ? <svg
              height={"15px"}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 40 20"
            >
              <defs />
              <g data-name="Ebene 2">
                <g data-name="Ebene 1">
                  <path fill="none" d="M0 0h40v20H0z" />
                  <path
                    fill="#37beb2"
                    d="M32.8 10l6.9-6.9a1 1 0 000-1.4L38.3.3a1 1 0 00-1.4 0L30 7.2 23.1.3a1 1 0 00-1.4 0l-1.4 1.4a1 1 0 000 1.4l6.9 6.9-6.9 6.9a1 1 0 000 1.4l1.4 1.4a1 1 0 001.4 0l6.9-6.9 6.9 6.9a1 1 0 001.4 0l1.4-1.4a1 1 0 000-1.4z"
                  />
                </g>
              </g>
            </svg> : <TextHighlight colorValue={"boldTurquoise"}>&nbsp;&nbsp;&nbsp;&nbsp;x</TextHighlight>}
          </FilterText>
        </ActiveFilter>
        <ActiveFilter isActiveValue={developerActive} setStateParent={setDeveloper} colorValue={"dev"}>
          <FilterText>
            <TextHighlight show={isBrowser() && window.innerWidth > shortFormWidth} colorValue={"blue"}>developer</TextHighlight>
            <TextHighlight show={isBrowser() && window.innerWidth <= shortFormWidth} colorValue={"blue"}>dev</TextHighlight>
            {isBrowser() && window.innerWidth > shortFormWidth ? <svg
              height={"15px"}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 40 20"
            >
              <defs />
              <g data-name="Ebene 2">
                <g data-name="Ebene 1">
                  <path fill="none" d="M0 0h40v20H0z" />
                  <path
                    fill="#5eb1ec"
                    d="M32.8 10l6.9-6.9a1 1 0 000-1.4L38.3.3a1 1 0 00-1.4 0L30 7.2 23.1.3a1 1 0 00-1.4 0l-1.4 1.4a1 1 0 000 1.4l6.9 6.9-6.9 6.9a1 1 0 000 1.4l1.4 1.4a1 1 0 001.4 0l6.9-6.9 6.9 6.9a1 1 0 001.4 0l1.4-1.4a1 1 0 000-1.4z"
                  />
                </g>
              </g></svg> : <TextHighlight colorValue={"boldBlue"}>&nbsp;&nbsp;&nbsp;&nbsp;x</TextHighlight>}
          </FilterText>
        </ActiveFilter>
        <ActiveFilter isActiveValue={infoArchActive} setStateParent={setInfoArch} colorValue={"ia"}>
          <FilterText>
            <TextHighlight show={isBrowser() && window.innerWidth > shortFormWidth} colorValue={"violet"}>information architect</TextHighlight>
            <TextHighlight show={isBrowser() && window.innerWidth <= shortFormWidth} colorValue={"violet"}>ia</TextHighlight>
            {isBrowser() && window.innerWidth > shortFormWidth ? <svg
              height={"15px"}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 40 20"
            >
              <defs />
              <g data-name="Ebene 2">
                <g data-name="Ebene 1">
                  <path fill="none" d="M0 0h40v20H0z" />
                  <path
                    fill="#9b99ff"
                    d="M32.8 10l6.9-6.9a1 1 0 000-1.4L38.3.3a1 1 0 00-1.4 0L30 7.2 23.1.3a1 1 0 00-1.4 0l-1.4 1.4a1 1 0 000 1.4l6.9 6.9-6.9 6.9a1 1 0 000 1.4l1.4 1.4a1 1 0 001.4 0l6.9-6.9 6.9 6.9a1 1 0 001.4 0l1.4-1.4a1 1 0 000-1.4z"
                  />
                </g>
              </g>
            </svg> : <TextHighlight colorValue={"boldViolet"}>&nbsp;&nbsp;&nbsp;&nbsp;x</TextHighlight>}
          </FilterText>
        </ActiveFilter>
        <ActiveFilter isActiveValue={visualDesignerActive} setStateParent={setVisualDesigner} colorValue={"vd"}>
          <FilterText>
            <TextHighlight show={isBrowser() && window.innerWidth > shortFormWidth} colorValue={"purple"}>visual designer</TextHighlight>
            <TextHighlight show={isBrowser() && window.innerWidth <= shortFormWidth} colorValue={"purple"}>vd</TextHighlight>
            {isBrowser() && window.innerWidth > shortFormWidth ? <svg
              height={"15px"}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 40 20"
            >
              <defs />
              <g data-name="Ebene 2">
                <g data-name="Ebene 1">
                  <path fill="none" d="M0 0h40v20H0z" />
                  <path
                    fill="#c685ff"
                    d="M32.8 10l6.9-6.9a1 1 0 000-1.4L38.3.3a1 1 0 00-1.4 0L30 7.2 23.1.3a1 1 0 00-1.4 0l-1.4 1.4a1 1 0 000 1.4l6.9 6.9-6.9 6.9a1 1 0 000 1.4l1.4 1.4a1 1 0 001.4 0l6.9-6.9 6.9 6.9a1 1 0 001.4 0l1.4-1.4a1 1 0 000-1.4z"
                  />
                </g>
              </g>
            </svg>  : <TextHighlight colorValue={"boldPurple"}>&nbsp;&nbsp;&nbsp;&nbsp;x</TextHighlight>}
          </FilterText>
        </ActiveFilter>
        <ActiveFilter isActiveValue={interactionDesignerActive} setStateParent={setInteractionDesigner} colorValue={"id"}>
          <FilterText>
            <TextHighlight show={isBrowser() && window.innerWidth > shortFormWidth} colorValue={"pink"}>interaction designer</TextHighlight>
            <TextHighlight show={isBrowser() && window.innerWidth <= shortFormWidth} colorValue={"pink"}>id</TextHighlight>
            {isBrowser() && window.innerWidth > shortFormWidth ? <svg
              height={"15px"}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 40 20"
            >
              <defs />
              <g data-name="Ebene 2">
                <g data-name="Ebene 1">
                  <path fill="none" d="M0 0h40v20H0z" />
                  <path
                    fill="#dc84d2"
                    d="M32.8 10l6.9-6.9a1 1 0 000-1.4L38.3.3a1 1 0 00-1.4 0L30 7.2 23.1.3a1 1 0 00-1.4 0l-1.4 1.4a1 1 0 000 1.4l6.9 6.9-6.9 6.9a1 1 0 000 1.4l1.4 1.4a1 1 0 001.4 0l6.9-6.9 6.9 6.9a1 1 0 001.4 0l1.4-1.4a1 1 0 000-1.4z"
                  />
                </g>
              </g>
            </svg>  : <TextHighlight colorValue={"boldPink"}>&nbsp;&nbsp;&nbsp;&nbsp;x</TextHighlight>}
          </FilterText>
        </ActiveFilter>
      </ActiveFilterWrapper>
      <CardSection>{cards}</CardSection>
      <KeepDiscoveringSection>
        <h4>• Keep discovering <TextHighlight colorValue={"tertiary"}>– new best practices are coming soon</TextHighlight> •</h4>
      </KeepDiscoveringSection>
      <Space big mSixteen />
      <ResourcesPTBackground />
      <ResourcesPT>
        <CATUppercase>
          <TextHighlight colorValue={"pink"}>Up Next</TextHighlight>
        </CATUppercase>
        <PTHeadline>
          You are <TextHighlight colorValue={"pink"}>hooked </TextHighlight>on
          the topic?
        </PTHeadline>
        <CATSubheadline>
          Take a look at selected high quality resources.
        </CATSubheadline>
        <PTButton>
          <Link to="/resources">
            <Button catPrimaryPink alignLeft>
              explore resources
            </Button>
          </Link>
        </PTButton>
      </ResourcesPT>
    </Layout>
  )
}
